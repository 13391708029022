<template>
  <div>
    <TopMenuSubheadline :title="title" />
    <div class="app-content-box bg-gray pb-5">
      <div class="wrapper-shelf" v-if="!isLoading">
        <div v-if="list.length == 0" class="mt-5">
          <EmptyShelf :top="top"/>
        </div>
        <ul v-if="list.length != 0" class="notification-list">
          <swipe-list
            ref="list"
            :items="list"
            item-key="id"
          >
            <template class="swipeout-content" v-slot="{ item }">
              <li class="notification profile-notification mt-5">
                <div class="slide-option no-border" >
                  <div class="slide-option-slide">
                    <div class="slide-option-content pl-4 pr-3">
                      <div class="row">
                       <router-link :to="{name: 'BooksDetails', params: {id: item.id}}">
                        <a>
                          <img :src="item.image ? coverUrl + item.image : coverUrl + 'no_cover.png'"  :alt="item.tytul"  class="lend-cover"/>
                        </a>
                       </router-link>
                      <div class="notification-content col-7">
                        <div class="notification-content-header">
                          <router-link :to="{name: 'BooksDetails', params: {id: item.id}}">
                            <div class="info-title">
                              <div class="book-title">{{item.tytul}}</div>
                            </div>
                          </router-link>
                          <router-link :to="{name: 'Authors', params: {id: item.author_id}}">
                            <div class="info-title mt-3 mb-3">
                              <a>{{item.autor}}</a>
                            </div>
                          </router-link>
                          <div class="stars-wrapper mt-1" v-if="item.rating > 0">
                            <star-rating :increment="0.5" active-color="#000" :star-size="starSize"
                            inactive-color="#F1F1F1" active-border-color="#000" border-color="#000000"
                            :border-width='borderWidth' :show-rating="false" :read-only="true"
                            :rounded-corners='rounded' :rating="item.rating" >
                            </star-rating>
                          </div>
                          <div class="mt-1" v-if="item.rating > 0">Ocena: {{parseFloat(item.rating, 10)}}</div>
                          <div class="info-title mt-1 mb-3" v-if="subtitle == 'Pożyczone'">
                            <button class="btn-black-border mt-3 mb-1" @click="remind(item.id, item.user_book_id, item.borrowed,  item.borrowed_to_name)">Wyślij przypomnienie</button>
                          </div>
                          <div class="info-title mt-3 mb-3" v-else-if="subtitle == 'Pożyczone od'">
                            <button class="btn-black-border w-100 mt-3 mb-1" @click="giveback(item.id)">Oddaj książkę</button>
                          </div>
                            <div v-else>
                            <router-link :to="{name: 'LendBook', params: {id: item.id}}">
                              <button class="btn-black-border mt-3 mb-1">Pożyczam</button>
                            </router-link>
                            </div>
                        </div>
                      </div>
                      <div class="container">
                      <ModalSlot title="" v-if="isPopupShown && checkid === item.id" @close-modal="closeModal">
                        <div class="text-center mb-2 modal-main-title" v-if="subtitle == 'Pożyczone'">Przypomnienie zostało wysłane</div>
                        <div class="text-center mb-2 modal-main-title" v-if="subtitle == 'Pożyczone od'">Powiadomienie o oddaniu książki zostało wysłane</div>
                        <button class="btn-black-full w-100 mt-3 mb-1" @click="closeModal(false)">Zamknij</button>
                      </ModalSlot>
                      <ModalSlot title="" v-if="emailPopup && checkid === item.id" @close-modal="closeModal">
                        <div class="title text-center mb-4">Wyślij przypomnienie</div>
                        <div class="remind-text">Podaj email, na który ma zostać wysłane przypomnienie dla <strong>{{borrowed_name}}</strong></div>
                        <ValidationObserver ref="observer" tag="form">
                          <ValidationProvider  :rules="{required: true, email: true}" v-slot="{errors}"  class="w-100">
                            <div><input type="text" v-model="email" class="email-input mt-2 mb-2 w-100" placeholder="Adres email"/></div>
                            <span class="form-error-info text-center">{{ errors[0] }}</span>
                          </ValidationProvider>
                        </ValidationObserver>
                        <button class="btn-black-full w-100 mt-3 mb-1" @click="submit(item.id, item.user_book_id)">Wyślij</button>
                        <button class="btn-black-border w-100 mt-0 mb-1" @click="closeModal(false)">Zamknij</button>
                      </ModalSlot>
                      <ModalSlot title="" v-if="emailSubmit" @close-modal="emailSubmit = false">
                        <div class="text-center">Wiadomość została wysłana</div>
                        <button class="btn-black-full w-100 mt-3 mb-1" @click="emailSubmit = false">Zamknij</button>
                      </ModalSlot>
                      </div>
                    </div>
                    </div>
                  </div>
                </div>
              </li>
              <div class="container">
                <hr class="section-spacer mt-4 mb-0" />
              </div>
            </template>
            <template v-slot:right="{ item }">
              <div @click="remove(item)" class="swipeout-action option-red text-center">
                      <a href="#">
                        <i class="far fa-trash-alt"></i>
                        <span class="d-block mt-1">
                          Usuń
                        </span>
                      </a>
              </div>
            </template>
          </swipe-list>
           <infinite-loading @infinite="handleAllBooks">
               <div slot="no-results"></div>
           </infinite-loading>
        </ul>
      </div>
      <div v-else>
        <Loader />
      </div>
    </div>
  </div>
</template>
<script>
import { SwipeList } from 'vue-swipe-actions'
import ModalSlot from '../components/ModalSlot'
import TopMenuSubheadline from '../components/TopMenuSubheadline'
import EmptyShelf from '../components/EmptyShelf'
import Loader from '../components/Loader'
import StarRating from 'vue-star-rating'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required, email } from 'vee-validate/dist/rules'

extend('required', {
  ...required,
  message: 'Pole jest obowiązkowe'
})

extend('email', {
  ...email,
  message: 'Podaj poprawny adres email'
})

export default {
  name: 'Shelf',
  components: {
    SwipeList,
    TopMenuSubheadline,
    EmptyShelf,
    Loader,
    StarRating,
    ModalSlot,
    ValidationProvider,
    ValidationObserver
  },
  props: {
    id: [Number, String]
  },
  data () {
    return {
      tabs: ['books', 'people'],
      page: 1,
      activeTab: 'books',
      booksInShelf: [],
      allBooks: [],
      list: [],
      email: '',
      checkid: 0,
      title: 'Twoja półka',
      subtitle: '',
      isLoading: true,
      starsReadOnly: true,
      rating: 1,
      borderWidth: 2,
      animate: true,
      rounded: true,
      starSize: 18,
      isPopupShown: false,
      emailPopup: false,
      emailSubmit: false,
      borrowed_name: '',
      top: false
    }
  },
  async created () {
    if (this.id) {
      await this.handleAllBooks()
    }
  },
  methods: {
    async submit (id, borrowid) {
      const valid = await this.$refs.observer.validate()
      if (valid === true) {
        this.remindByEmail(id, borrowid)
      }
    },
    remindByEmail (id, borrowid) {
      const getData = `user=${borrowid}&email=${this.email}`
      this.$https('book/' + id + '/remind', {
        method: 'POST',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: getData
      }).then(response => {
        this.isPopupShown = false
        this.emailSubmit = true
        this.checkid = 0
      })
        .catch(error => {
          console.log('', error)
        })
    },
    closeModal () {
      this.isPopupShown = false
      this.emailPopup = false
      this.email = ''
      this.checkid = 0
    },
    remind (id, userId, type, borrowed) {
      if (type === 'yes') {
        const getData = `user=${userId}`
        this.$https('book/' + id + '/remind', {
          method: 'POST',
          mode: 'no-cors',
          crossDomain: true,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          data: getData
        }).then(response => {
          this.checkid = id
          this.isPopupShown = true
        })
          .catch(error => {
            console.log('', error)
          })
      } else if (type === 'out') {
        this.checkid = id
        this.borrowed_name = borrowed
        this.emailPopup = true
      }
    },
    giveback (id) {
      this.$https('/book/' + id + '/givebackbyuser', {
        method: 'POST',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then(response => {
        this.checkid = id
        this.isPopupShown = true
      })
        .catch(error => {
          console.log('', error)
        })
    },
    handleAllBooks ($s) {
      this.$https(`/shelf-books/${this.id}?page=${this.page}`, {
        method: 'GET',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
        .then(({ data }) => {
          this.isLoading = false
          this.allBooks = data.response[0].items
          this.top = data.response[0].type === 'top'
          this.title = 'Wszystkie z półki: ' + data.response[0].label
          this.subtitle = data.response[0].label
          if (this.allBooks.length) {
            this.page += 1
            this.allBooks.forEach(e => {
              this.list.push(e)
            })
            $s.loaded()
          } else {
            $s.complete()
          }
        })
        .catch(error => {
          console.log('', error)
        })
    },
    remove (item) {
      this.list = this.list.filter(i => i !== item)
      const getData = `books=${item.user_book_id}&from=${this.id}`
      this.$https('delete-books-from-shelf', {
        method: 'POST',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: getData
      }).then(response => {
      })
        .catch(error => {
          console.log('', error)
        })
    },
    close (e) {
      this.$refs.list.closeActions(e.id)
    }
  }
}
</script>
<style lang="scss" scoped>
  .app-content-box {
    min-height: 100vh;
  }
 .wrapper-shelf {
    margin-top: 30px;
    margin-bottom: 20px;
  }
  .lend-cover {
    width: 140px;
    height: 216px;
  }
  @media screen and (max-width: 320px) {
    .lend-cover {
      width: 112px;
      height: 172px;
    }
  }
  .wrapper-book-lend {
    padding: 0 50px;
  }
  .swipeout-content {
    background-color: #F1F1F1;
  }
  .info-title {
    &:hover, &:focus, &:active {
      text-decoration: underline;
    }
  }
  .book-title {
    width: 100%;
    word-break: break-word;
    font-size: 1.55rem;
    line-height: 1.8rem;
  }
  .stars-wrapper {
    display: flex;
    justify-content: flex-start;
  }
  .title {
    font-size: 28px;
    line-height: 32px;
  }
  .remind-text {
    font-size: 15px;
  }
  .email-input {
    border: none;
    border-bottom: 1px solid #000;
  }
</style>
